<template>
  <div class="container">
    <section>
      <v-row class="d-flex align-center">
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Atividades produtivas"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Entre as atividades tradicionalmente desenvolvidas em Bento
                Rodrigues, destaca-se o uso da terra para plantio e criação de
                animais. As práticas produtivas foram realizadas desde o período
                colonial até a contemporaneidade, quando em novembro de 2015
                foram interrompidas pela lama de rejeitos que destruiu a
                comunidade.
              </p>
              <p>
                Nos sítios e fazendas, além de hortas, pomares e criação de
                pequenos e médios animais para
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_cultura_alimentar' }"
                  >consumo próprio</router-link
                >, trocas e comercialização, havia extensões de pastagem
                destinada à criação de bovinos, plantio de eucalipto e produção
                de carvão vegetal, roças diversificadas e tanques para criação
                de peixes.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-md-end align-md-end justify-center align-center"
        >
          <v-img
            max-width="80%"
            src="../../assets/sobre_territorio_bento_atividades_01.png"
          ></v-img>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-start align-md-start"
        >
          <v-img
            max-width="70%"
            src="../../assets/sobre_territorio_bento_atividades_02.png"
          ></v-img>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                No núcleo urbano, os terrenos caracterizavam-se pelos quintais
                produtivos, onde eram cultivadas hortas, árvores frutíferas,
                roças diversas e criados animais de pequeno e médio porte para
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_cultura_alimentar' }"
                  >consumo próprio</router-link
                >, trocas com outros moradores, além da comercialização de
                hortaliças, frutas, ovos e carne de aves, fazendo parte da renda
                familiar.
              </p>
              <p>
                Os terrenos apresentavam topografia praticamente plana, onde o
                solo era próprio para o plantio, havendo nascentes e córregos
                proporcionando umidade adequada. As áreas produtivas
                correspondiam às maiores extensões dos terrenos, de modo que os
                cultivos recebiam insolação necessária durante o período diário
                para seu bom desenvolvimento.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-start">
        <v-col cols="12" md="5">
          <v-img
            src="../../assets/sobre_territorio_bento_atividades_03.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.6em; width:100%; text-align: center;"
            class="mt-2"
          >
            Quintal produtivo do atingido Marcus Muniz, com a criação de
            galinhas e cultivo de horta. Foto: Manoel Marcos Muniz / Jornal A
            Sirene, 2017.
          </p>
        </v-col>
        <v-col cols="12" md="5">
          <v-img
            src="../../assets/sobre_territorio_bento_atividades_04.png"
          ></v-img>
          <p
            style="font-size: 0.6em; width:100%; text-align: center;"
            class="mt-2"
          >
            Quintal produtivo do atingido Marcus Muniz, com árvores frutíferas.
            Foto: Manoel Marcos Muniz / Jornal A Sirene, 2017.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex align-center">
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Transformações ocorridas por fatores externos"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Se por um lado Bento Rodrigues foi naturalmente se adaptando às
                condições socioeconômicas que ocorreram em Minas e no município
                de Mariana desde meados do século XVIII e principalmente no XIX,
                por outro, forçosamente ocorreram transformações em virtude da
                implantação no entorno imediato de grandes empreendimentos
                relacionados à atividade minerária já na segunda metade do
                século XX.
              </p>
              <p>
                Em 1960, a empresa Novelis do Brasil instalou-se no terreno do
                Morro do Fraga, próximo ao núcleo urbano, ali operando na
                extração de bauxita. No final dos anos 70, foi construída a Mina
                de Germano pela empresa Samarco Mineração S.A, a 3km da Capela
                de São Bento, destinada à extração de minério de ferro.
              </p>
              <p>
                A comunidade que, apesar de possuir um núcleo urbano conformado,
                caracterizava-se por um contexto rural, tanto pela predominância
                de áreas livres produtivas em comparação com as edificadas,
                quanto pela tranquilidade, passou a conviver com o aumento do
                trânsito de veículos que circulavam pela região em virtude da
                atividade minerária e com a alteração do meio ambiente natural,
                cujas montanhas foram sendo escavadas, e a uma distância muito
                próxima, implantadas barragens a montante.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-md-end align-md-end justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_bento_atividades_05.png"
          ></v-img>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p class="mb-0">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <strong>Referências Bibliográficas</strong>
            </p>
            <p>
              CASTRIOTA, Leonardo Barci. Dossiê de Tombamento de Bento
              Rodrigues. UFMG. Belo Horizonte, 2019. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento"
                class="pink--text"
                >https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento</a
              >. Acesso em: 30 mar. 2022.
            </p>
            <p>
              PÓLEN Consultoria Patrimônio e Projetos Ltda. Diagnóstico
              preliminar dos bens culturais identificados no território atingido
              em Mariana pelo rompimento da barragem de Fundão. Mariana, 2017.
            </p>
            <p>
              TEIXEIRA, Vanessa Cerqueira. Fé e cultura barroca sob o manto
              mercedário: devoção, religiosidade e vivências a partir da
              irmandade de Nossa Senhora das Mercês de Mariana (1749-1822). 220
              f. 2017. Dissertação (Mestrado em História) — Instituto de
              Ciências Humanas, Universidade Federal de Juiz de Fora Juiz de
              Fora-MG, 2017.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
